import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/layout'
import Home from '@/views/layout/home'
import Category from '@/views/layout/category'
import Cart from '@/views/layout/cart'
import User from '@/views/layout/user'
import store from '@/store'

// 路由懒加载   根据页面需求加加载路由
const Login = () => import('@/views/login')
const Search = () => import('@/views/search')
const SearchList = () => import('@/views/search/list')
const ProDetail = () => import('@/views/prodetail')
const Pay = () => import('@/views/pay')
const MyOrder = () => import('@/views/myorder')

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    // 一级路由
    { path: '/login', component: Login },
    {
      path: '/',
      redirect: '/home', // 路由重定向
      component: Layout,
      children: [ // 二级路由
        { path: '/home', component: Home },
        { path: '/category', component: Category },
        { path: '/cart', component: Cart },
        { path: '/user', component: User }
      ]
    },
    { path: '/search', component: Search },
    { path: '/searchlist', component: SearchList },
    // 动态路由传参
    { path: '/prodetail/:id', component: ProDetail },
    { path: '/pay', component: Pay },
    { path: '/myorder', component: MyOrder }
  ]
})

// 全局前置导航守卫
// to:到哪儿去，到哪儿去的完整路由信息对象
// from：从哪里来
// next()是否放行

// 定义一个数，专门存放需要权限访问的页面
const authUrls = ['/pay', '/myorder']
router.beforeEach((to, from, next) => {
  // 看to.path是否在authUrls中出现
  if (!authUrls.includes(to.path)) {
    next() // 非权限页面，直接放行
    return
  }
  // 是权限页面进行拦截
  const token = store.getters.token
  if (token) {
    next()
  } else {
    next('/login')
  }
})

export default router
